<template id="task_add_modal-template">
    <div class="d-inline-block">
        <a @click.prevent="openModal" class="dropdown-item delete-board d-flex align-items-center"
            href="javascript:void(0)"> <i class="bx bx-plus bx-xs"></i>
            <span class="align-middle">Dodaj nowe zadanie</span></a>
        <b-modal v-model="show" @ok="saveModal()" title="Dodaj zadanie" hide-footer class_other="modal-xlg">
            <task_edit_form :project_id="project_id" :post_id="0" type="create" @ok="saveModal" @cancel="closeModal">
            </task_edit_form>
        </b-modal>
    </div>
</template>
<script>
import MagicoModal from '../../../components/MagicoModal.vue';
import TaskEditForm from './TaskEditForm.vue';
export default {
    name: 'TaskAddModal',
    components: {
        'b-modal': MagicoModal,
        'task_edit_form': TaskEditForm
    },
    props: ['post', 'client_id', 'project_id'],
    data: function () {
        return {
            show: false,
            item: '',
            id: '',
        }
    },
    computed: {},
    methods: {
        lang(x) { return x },
        openModal() {
            this.show = true;
        },
        closeModal() {
            this.show = false;
        },
        saveModal() {
            this.show = false;
            let vm = this;
            setTimeout(function () {

                vm.$emit('change', this.item);
            }, 500);
        }
    },
    created() {
        this.pojectId = this.project_id
        this.id = this.client_id
    }
}
</script>