<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
            <div class="col-md-6 col-lg-6 mb-4">
                <TaskDashbard></TaskDashbard>
            </div>
            <div class="col-md-6 col-lg-6 mb-4">
                <TaskMeDashbard></TaskMeDashbard>
            </div>
        </div>
    </div>
</template>
<script>
import api from '../../api/api';
import TaskDashbard from '../project/dashboard/TaskDashbard.vue';
import TaskMeDashbard from '../project/dashboard/TaskMeDashbard.vue';
export default {
    components: {
        TaskDashbard, TaskMeDashbard
    },
    data: function () {
        return {
            me: {},
        }
    },
    mounted() {
        this.getMe();
    },
    methods: {
        logout: function () {
            localStorage.removeItem('algorytm_token');
            window.location.reload();
        },
        getMe: function () {
            api.me().then((response) => {
                this.me = response.data.data;
                this.$store.commit('setUser', this.me);
            })
        }
    }
}
</script>