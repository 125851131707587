<template>
    <div class="card h-100">
        <div class="card-header d-flex align-items-center justify-content-between">
            <h5 class="card-title m-0 me-2">
                Lista zadań dla innych
            </h5>
            <div class="col-12 col-xl-4 me-4">

            </div>
            <div class="dropdown">
                <button class="btn p-0" type="button" id="customerRatings" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="customerRatings" style="">
                    <TaskAddModal @change="getPosts" :project_id="0"></TaskAddModal>
                    <a @click="search.task_status = 'progress,todo,check'; getPosts()" href="javascript:void(0)"
                        class="dropdown-item delete-board d-flex align-items-center"><i class="bx bx-item bx-xs"></i><span
                            class="align-middle">Pokaż wszystkie</span></a>
                    <a @click="search.task_status = 'todo'; getPosts()" href="javascript:void(0)"
                        class="dropdown-item delete-board d-flex align-items-center"><i class="bx bx-item bx-xs"></i><span
                            class="align-middle">Pokaż do zrobienia</span></a>
                    <a @click="search.task_status = 'progress'; getPosts()" href="javascript:void(0)"
                        class="dropdown-item delete-board d-flex align-items-center"><i class="bx bx-item bx-xs"></i><span
                            class="align-middle">Pokaż w toku</span></a>
                    <a @click="search.task_status = 'check'; getPosts()" href="javascript:void(0)"
                        class="dropdown-item delete-board d-flex align-items-center"><i class="bx bx-item bx-xs"></i><span
                            class="align-middle">Pokaż do sprawdzenia</span></a>
                    <a @click="search.task_status = 'done'; getPosts()" href="javascript:void(0)"
                        class="dropdown-item delete-board d-flex align-items-center"><i class="bx bx-item bx-xs"></i><span
                            class="align-middle">Pokaż gotowe</span></a>
                </div>
            </div>
        </div>
        <div class="card-body pb-0">
            <div class="card-datatable table-responsive">
                <table class="invoice-list-table table border-top">
                    <thead>
                        <tr>
                            <th class="cell-fit">{{ $t('task.user_id') }}</th>
                            <th>{{ $t('task.task_name') }}</th>
                            <th>{{ $t('task.task_status') }}</th>
                            <th class="cell-fit">{{ $t('task.task_end_at') }}</th>
                        </tr>
                    </thead>
                    <tbody class="table-border-bottom-0">
                        <tr v-for="item, index in items" :key="index">
                            <td>
                                <div class="d-flex justify-content-start align-items-center">
                                    <div class="avatar-wrapper">
                                        <MagicoAvatar :user_id="item.user_id" :size="32"></MagicoAvatar>
                                        
                                    </div>
                                </div>
                            </td>
                            <td> <router-link class="d-flex flex-column" :to="'/task/1/show/' + item.task_id">
                                    {{ item.task_name }}</router-link>
                                <div class="d-flex flex-column" v-if="getUserName(item.task_created_user)">Zadanie od
                                    <span class="text-c-green"> {{ getUserName(item.task_created_user) }}</span>
                                </div>
                            </td>
                            <td>
                                <span v-if="item.task_status == 'progress'" class="badge bg-label-success">w toku</span>
                                <span v-if="item.task_status == 'todo'" class="badge bg-label-success">do zrobienia</span>
                                <span v-if="item.task_status == 'check'" class="badge bg-label-success">do
                                    sprawdzenia</span>
                                <span v-if="item.task_status == 'done'" class="badge bg-label-success">gotowe</span>
                            </td>
                            <td>
                                {{ dateToPl(item.task_end_at) }}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from '../../../api/axios';
import api from "../../../api/api"
import TaskAddModal from '../components/TaskAddModal.vue';
import MagicoAvatar from '../../../components/MagicoAvatar.vue';

export default {
    name: 'TaskMeDashbard',
    props: ['data'],
    components: {
    TaskAddModal,
    MagicoAvatar
},
    data: function () {
        return {
            time: '',
            BASE_URL: api.getBaseUrl(),
            date: '',
            items: [],
            users: [],
            search: {
                limit: 15,
                add_client: 1,
                orderby: 'client_com_nextcontact_at',
                sort: 'ASC',
                next_contact_at: '',
                task_status: 'progress,todo,check',
                includeuser: 1,
                forothers: 1,
            }
        }
    },
    methods: {
        getUserName(user_id) {
            let user = null
            this.users.forEach(item => {
                if (item.user_id == user_id) user = item;
            })
            return user ? user.name : '';
        },
        getUserImage(user_id) {
            let user = null
            this.users.forEach(item => {
                if (item.user_id == user_id) user = item;
            })
            return (user && user.avatar) ? '/' + user.avatar : '/defaultuser.png';
        },
        dateToPl(date) {
            var newdate = dayjs(date);
            console.log(newdate.isValid());
            if (newdate.isValid()) return newdate.format('DD MMMM YYYY HH:mm', "PL-pl")
            return '';
        },
        changeStatus(item) {
            if (item.client_com_status == 'open') {
                item.client_com_status = 'close'
            } else {
                item.client_com_status = 'open'
            }
            this.patchPost(item);
        },
        getTitle(item) {
            if (item.client_com_status == 'open') return 'Telen nie byl wykonywany';
            return 'Zadanie wykonane';
        },
        clickComment(item) {
            console.log(item)
            location.replace('/panel/client#/comment/' + item.client_id); //+'/edit/'+item.client_com_id);
        },
        handleAxiosError: function (error) {
            if (error.response.data.messages) {
                for (const property in error.response.data.messages) {
                    this.validation[property] = error.response.data.messages[property];
                }
            } else console.log(error);
        },
        patchPost: function (item) {
            var data = {
                client_com_status: item.client_com_status
            }
            axios.patch(this.BASE_URL + 'api/v1/client/comment/' + item.client_com_id, data).then(() => { }).catch(this.handleAxiosError);
        },
        getPosts: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/task?includeuser=1', {
                params: this.search
            }).then(response => {
                vm.items = response.data.data;
                vm.users = response.data.includes.user; let vm = this;

                this.time = dayjs().format('HH:mm')
                this.date = dayjs().format('YYYY-MM-DD')
                this.search.next_contact_at = this.date;
            }
            )
        }
    }
}

</script>