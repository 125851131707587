<template id="task_edit_form-template">
    <form class="row" @submit.prevent="submitForm">
        <div class="col-12 col-md-8">
            <div class="card-body">
                <div class="form-group mb-2 row">
                    <div class="col-12 col-md-3">
                        <label class="form-label">
                            {{ $t('task.task_end_at') }}
                        </label>
                    </div>
                    <div class="col-12 col-md-9">
                        <date-picker type="datetime" format="YYYY-MM-DD HH:mm" :show-second="false" :minute-step="10"
                            :class="{ 'is-invalid': validation.task_end_at != '' }" class="w-100" v-model="item.task_end_at"
                            value-type="YYYY-MM-DD HH:mm:ss" input-class="form-control"></date-picker>
                        <div class="invalid-feedback">{{ validation.task_end_at }}</div>
                    </div>
                </div>

                <div class="form-group mb-2 row">
                    <div class="col-12 col-md-3">
                        <label class="form-label">
                            {{ $t('task.task_name') }}
                        </label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="text" class="form-control" :class="{ 'is-invalid': validation.task_name != '' }"
                            v-model="item.task_name">
                        <div class="invalid-feedback">{{ validation.task_name }}</div>
                    </div>
                </div>
                <div class="form-group mb-2 row">
                    <div class="col-12 col-md-3">
                        <label class="form-label">
                            {{ $t('task.task_description') }}
                        </label>
                    </div>
                    <div class="col-12 col-md-9">
                        <editor :init="vtinymce" v-model="item.task_description" rows="8"
                            :class="{ 'is-invalid': validation.task_description != '' }"></editor>
                        <div class="invalid-feedback">{{ validation.task_description }}</div>
                    </div>
                </div>
                <div class="form-group mb-2 row">
                    <div class="col-12 col-md-3">
                        <label class="form-label">
                            {{ $t('task.user_id') }}
                        </label>
                    </div>
                    <div class="col-12 col-md-9">
                        <select class="form-control" v-model="item.user_id"
                            :class="{ 'is-invalid': validation.user_id != '' }">
                            <option value="">Nieprzypisane</option>
                            <option v-for="i, index in slownik_user" :value="i.user_id" :key="index">{{
                                i.name ? i.name : i.username }}</option>
                        </select>
                        <div class="invalid-feedback">{{ validation.user_id }}</div>
                    </div>
                </div>
                <div class="form-group mb-2 row">
                    <div class="col-12 col-md-3">
                        <label class="form-label">
                            {{ $t('task.task_priority') }}
                        </label>
                    </div>
                    <div class="col-12 col-md-9">
                        <select class="form-control" v-model="item.task_priority"
                            :class="{ 'is-invalid': validation.user_id != '' }">
                            <option value="low">
                                {{ $t('task.header.low') }}
                            </option>
                            <option value="normal">
                                {{ $t('task.header.normal') }}
                            </option>
                            <option value="hight">
                                {{ $t('task.header.hight') }}
                            </option>
                        </select>
                        <div class="invalid-feedback">{{ validation.user_id }}</div>
                    </div>
                </div>
                <div class="text-center">
                    <button type="submit" @click.prevent="submitForm" class="btn btn-outline-success me-2">
                        {{ $t('task.button.save') }}
                    </button>
                    <button @click.prevent="cancelForm" class="btn btn-outline-secondary">
                        {{ $t('task.button.cancel') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4">
            <div class="card" v-if="isEdit">
                <div class="card-header">
                    <h5>
                        {{ $t('task.header.settings') }}
                    </h5>
                    <div class="card-header-right">
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group mb-2">
                        <label class="form-label">
                            {{ $t('task.task_status') }}
                        </label>
                        <select class="form-control" v-model="item.task_status"
                            :class="{ 'is-invalid': validation.task_status != '' }">
                            <option value="todo">
                                {{ $t('task.header.todo') }}
                            </option>
                            <option value="progress">
                                {{ $t('task.header.progress') }}
                            </option>
                            <option value="done">
                                {{ $t('task.header.done') }}
                            </option>
                        </select>
                        <div class="invalid-feedback">{{ validation.task_status }}</div>
                    </div>
                    <div class="form-group mb-2 row" v-if="item.task_created_user == user_id">
                        <label class="form-label col">
                            {{ $t('task.task_delete') }}
                        </label>
                        <div class="col text-right">
                            <delete_confirm :text="lang('task.delete.body')" :url="'/api/v1/task/' + item.task_id"
                                @confirm="onDelete" @error="onDelete"></delete_confirm>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <h5>
                        {{ $t('task.header.notification') }}
                    </h5>
                    <div class="card-header-right">
                        <button class="btn btn-outline-primary" @click.prevent="addNotification">Dodaj</button>
                    </div>
                </div>
                <div class="card-bodyx ps-12 pe-12">
                    <div v-for="notification, index in this.item.notifications" :key="index"
                        class="d-flex align-items-center mb-2">

                        <input :disabled="notification.task_notification_status == 'done'" class="form-control me-1"
                            type="number" max="99" style="width: 8ch;" v-model="notification.task_notification_number">
                        <select :disabled="notification.task_notification_status == 'done'" class="form-control"
                            v-model="notification.task_notification_type">
                            <option value="hour">godziny</option>
                            <option value="day">dni</option>
                            <option value="week">tygodnie</option>
                            <option value="month">miesiące</option>
                            <option value="year">lata</option>
                        </select>
                        <span class="pl-2 pe-12">wcześniej</span>
                        <button type="button" @click.prevent="deleteNotification(index)"
                            class="btn btn-icon-sm btn-outline-secondary me-1 off_tooltip_off" title="delete"><i
                                class="material-icons-outlined">delete</i></button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import tinymceConfig from '../../../components/tinimce/config.js'
import Editor from '@tinymce/tinymce-vue'
import axios from '../../../api/axios';
import DatePicker from 'vue2-datepicker'
import api from '../../../api/api';
export default {
    name: 'task_edit_form',
    template: '#task_edit_form-template',
    components: {
        'editor': Editor,
        'date-picker': DatePicker
    },
    props: ['post_id', 'type', 'project_id'],
    computed: {
        slownik_user: function () { return this.$store.state.slownik_user },

    },
    data: function () {
        return {
            BASE_URL: api.getBaseUrl(),
            vtinymce: tinymceConfig,
            isLoading: true,
            id: 0,
            isEdit: false,
            item: {
                task_name: '',
                task_status: 'todo',
                task_description: '',
                task_priority: 'low',
                user_id: '',
                task_module: '',
                task_post_id: 0,
                task_end_at: '',
                notifications: [],
            },
            header: {
                task_name: '',
            },
            validation: {
                task_name: '',
                task_description: '',
                task_status: '',
                task_priority: '',
                user_id: '',
                task_end_at: '',
            }
        }
    },
    methods: {
        lang(x) { return x },
        onDelete: function () {
            this.$emit('ok')
        },
        deleteNotification: function (index) {
            this.item.notifications.splice(index, 1);
        },
        addNotification: function () {
            this.item.notifications.push({
                task_notification_id: '',
                task_notification_number: '',
                task_notification_type: '',
                task_notification_status: '',
                task_id: 0,
                task_notification_end_at: '',
                task_notification_created_at: '',
                task_notification_updated_at: '',
                task_notification_deleted_at: '',
            })
        },
        cancelForm: function () {
            this.$emit('cancel');
        },
        submitForm: function () {
            for (const property in this.validation) {
                this.validation[property] = '';
            }
            for (const tablica in this.representative_validation) {
                for (const property in this.representative_validation[tablica]) {
                    this.representative_validation[tablica][property] = '';
                }
            }
            if (this.isEdit > 0) {
                this.updatePost();
            } else {
                this.createPost();
            }
            console.log(this.item);
        },
        getPost: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/task/' + this.id).then(response => {
                //stworzenie pustych walidacji
                console.log(response.data.representative);
                vm.item = response.data;
                vm.header.task_name = vm.item.task_name;
                vm.isLoading = false;
            });
        },
        handleAxiosError: function (error) {
            console.log('error', error);
            if (error.response.data.messages) {
                for (const property in error.response.data.messages) {
                    this.validation[property] = error.response.data.messages[property];
                }
            } else console.log(error);
        },
        createPost: function () {
            var vm = this;
            if (this.project_id) {
                this.item.task_module = 'project'
                this.item.task_post_id = this.project_id
            } else if (!this.item.user_id) {
                this.item.user_id = this.$store.state.user.id;
            }
            axios.post(this.BASE_URL + 'api/v1/task', this.item).then(() => {
                this.$emit('ok')
            }).catch(error => {
                vm.handleAxiosError(error);
            });
        },
        updatePost: function () {
            axios.put(this.BASE_URL + 'api/v1/task/' + this.id, this.item).then(() => {
                this.$emit('ok')
            }).catch(this.handleAxiosError);
        },
    },
    created() {
        this.id = this.post_id;
        this.isEdit = (this.type == 'edit') ? 1 : 0;
        if (this.isEdit) {
            this.getPost()
        }
    }
}
</script>
